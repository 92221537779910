import * as React from "react"

import Layout from "../components/layout"
import {Avatar, Box, Container, Flex, Heading, Icon, Link, Text} from "@chakra-ui/react";

import {motion} from "framer-motion";
import ScatterWithLabel from "../components/graph/ScatterWithLabel";
import {LineVocabTest} from "../components/graph/LineVocabTest";
import {ContentIndex} from "../components/mainPage/ContentIndex";
import {DEBUG} from "../components/tools";

const Lab = ({ }) => {
  return (
    <Layout title={"le projet"}>
        <Container maxW="container.xl" sx={{
                margin: `10px auto`,

                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>
            {!DEBUG && <ContentIndex />}
            {DEBUG && <LineVocabTest/>}
        </Container>
    </Layout>
  )
}
export default Lab